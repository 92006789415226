import styled from 'styled-components';

export const SectionContainer = styled.div`
  width: 100%;  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  & > div {
    padding-right: 50px;
  }

  & > div:last-child {
    padding-right: 0px;
  }

  @media (max-width: 992px) {
    & > div {
      padding-right: 0px;
    }
  }
`;

export const SectionContent = styled.div`
  flex-basis: 50%;

  @media (max-width: 992px) {
    flex-basis: 100%;
  }
`;

export const YearValue = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  font-size: 20px;

  span {
    font-size: 26px;
  }
`;

export const DateFilterSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  padding-left: 82px;
`;

export const BackButton = styled.div`
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
`;

export const Year = styled.div`
  display: inline-block;
  font-size: 22px;
  margin: 10px 15px 0 15px;
  text-transform: capitalize;
  user-select: none;
`;

export const NextButton = styled.div`
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
`;

export const GraphsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SectionTitle = styled.h2`
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: left;
`;