import client from '../config/AxiosConfig';
import { auth_token_key } from '../config/LocalStorageKeys';

export const GetProcessList = async (currentPage, limit, filters) =>
  await client.get(`/process/${currentPage}/${limit}?filter=${filters}`);

export const GetUserProcessFullList = async () =>
  await client.get('/process/user');

export const GetUserFullProcessList = async (id) =>
  await client.get(`/process/user/${id}`);

export const GetProcess = async (identifier) =>
  await client.get(`/process/${identifier}`);

export const CreateProcess = async (data) =>
  await client.post(`/process`, data);

export const UpdateProcess = async (identifier, data) =>
  await client.put(`/process/${identifier}`, data);

export const DeleteProcess = async (identifier) =>
  await client.delete(`/process/${identifier}`);

export const UpdateProcessState = async (identifier, data) =>
  await client.put(`/process/state/${identifier}`, data);

export const UpdateProcessResponsible = async (identifier, data) =>
  await client.put(`/process/responsible/${identifier}`, data);

export const UpdateProcessLanguage = async (identifier, data) =>
  await client.put(`/process/language/${identifier}`, data);

export const UpdateProcessClientData = async (identifier, data) =>
  await client.put(`/process/client/${identifier}`, data);

export const UpdateProcessCommissions = async (identifier, data) =>
  await client.put(`/process/commission/${identifier}`, data);

export const UpdateProcessSendEmails = async (identifier, data) =>
  await client.put(`/process/pending_payments_emails/${identifier}`, data);

export const SendProcessPdf = async (identifier, filters) =>
  await client.get(`/automatic_pdf/email/${identifier}?filter=${filters}`);

export const SaveDocument = async (id, payload) =>
  await client.put(`/process/${id}/documents`, payload);

export const RemoveDocument = async (id, file) =>
  await client.delete(`/process/${id}/documents/${file}`);

export const GetProcessByYear = async (year) =>
  await client.get(`/dashboard/year/${year}`);

export const GetPaymentsByYear = async (year) =>
  await client.get(`/dashboard/payments/year/${year}`);

export const GetServiceByMonth = async (year, month) =>
  await client.get(`/dashboard/service/${year}/${month}`);

export const GetServiceTypeByMonth = async (year, month) =>
  await client.get(`/dashboard/type/${year}/${month}`);

export const GetResponsiblesByMonth = async (year, month) =>
  await client.get(`/dashboard/responsibles/${year}/${month}`);

export const GetAgentsByMonth = async (year, month) =>
  await client.get(`/dashboard/agents/${year}/${month}`);

export const GetPartnersByMonth = async (year, month) =>
  await client.get(`/dashboard/partners/${year}/${month}`);

export const GetProcessUpdates = async (id) =>
  await client.get(`/process_updates/${id}`);

export const SaveProcessUpdate = async (data) =>
  await client.post(`/process_updates`, data);

export const DeleteProcessUpdate = async (id) =>
  await client.delete(`/process_updates/${id}`);

export const EditProcessUpdate = async (id, data) =>
  await client.put(`/process_updates/${id}`, data);

export const GetClientProcessList = async (id, currentPage, limit) =>
  await client.get(`/process/client/${id}/${currentPage}/${limit}`);

export const GetProcessMBReferences = async (id) =>
  await client.get(`/process_references/${id}`);

export const SaveProcessMBReference = async (data) =>
  await client.post(`/process_references`, data);

export const SendEmailMbReference = async (data) =>
  await client.post(`/process/mb_payment/email`, data);

export const SendEmailBankTransfer = async (data) =>
  await client.post(`/process/bank_transfer/email`, data);

export const SendEmailInstitutionZentralstelle = async (data) =>
  await client.post(`/process/institution/zentralstelle/email`, data);

export const SendEmailInstitutionZAS = async (data) =>
  await client.post(`/process/institution/zas/email`, data);

export const SendEmailInstitution = async (data) =>
  await client.post(`/process/institution/email`, data);

export const SendPersonalizedEmail = async (data) =>
  await client.post(`/process/email`, data);

export const GetAgentProcessList = async (currentPage, limit, filters) =>
  await client.get(`/process/agent/${currentPage}/${limit}?filter=${filters}`);

export const GetProcessFilesList = async (id, filters) =>
  await client.get(`/process_files/${id}?filter=${filters}`);

export const GetProcessProcurations = async (id) =>
  await client.get(`/process_files/${id}/procurations`);

export const GetProcessDocuments = async (id) =>
  await client.get(`/process_files/${id}/documents`);

export const DeleteProcessFile = async (id) =>
  await client.delete(`/process_files/${id}`);

export const SaveProcessFile = async (data) =>
  await client.post(`/process_files`, data);

export const SendEmailSignature = async (data) =>
  await client.post(`/process_files/email`, data);

export const UpdateProcessFile = async (id, data) =>
  await client.put(`/process_files/${id}`, data);

export const GetProcessInstitutionHistory = async (id, filters) =>
  await client.get(`/process_institution_history/${id}?filter=${filters}`);

export const EditFilename = async (id, data) =>
  await client.put(`/files/${id}`, data);

export const ToggleProcessArchive = async (identifier, data) =>
  await client.put(`/process/${identifier}/archive`, data);

export const GetProcessNotes = async (process, currentPage, limit, filters) =>
  await client.get(`/process_notes/${process}/${currentPage}/${limit}?filter=${filters}`);

export const CreateNote = async (data) =>
  await client.post(`/process_notes`, data);

export const UpdateNote = async (identifier, data) =>
  await client.put(`/process_notes/${identifier}`, data);

export const DeleteNote = async (identifier) =>
  await client.delete(`/process_notes/${identifier}`);

export const GetLemaniaList = async (currentPage, limit, filters) =>
  await client.get(`/process_lemania/${currentPage}/${limit}?filter=${filters}`);

export const GetProcessLemania = async (process) =>
  await client.get(`/process_lemania/${process}`);

export const CreateLemania = async (data) =>
  await client.post(`/process_lemania`, data);

export const UpdateLemania = async (identifier, data) =>
  await client.put(`/process_lemania/${identifier}`, data);

export const DeleteLemania = async (identifier) =>
  await client.delete(`/process_lemania/${identifier}`);

export const GetProcessPayments = async (process, filters) =>
  await client.get(`/process_payments/${process}?filter=${filters}`);

export const CreatePayment = async (data) =>
  await client.post(`/process_payments`, data);

export const UpdatePayment = async (identifier, data) =>
  await client.put(`/process_payments/${identifier}`, data);

export const DeletePayment = async (identifier) =>
  await client.delete(`/process_payments/${identifier}`);

export const SendPaymentEmail = async (data) =>
  await client.post(`/process_payments/email`, data);

export const GetProcessLogs = async (currentPage, limit, filters) =>
  await client.get(`/logs/${currentPage}/${limit}?filter=${filters}`);

export const CreateLog = async (data) =>
  await client.post(`/logs`, data);

export const DeleteLog = async (identifier) =>
  await client.delete(`/logs/${identifier}`);

export const GetLastProcessLog = async (identifier) =>
  await client.get(`/logs/last/${identifier}`);

export const ExportLogs = data => { 
  return new Promise((accept, reject) => {
    fetch(`${process.env.REACT_APP_API}/logs/download`, { 
      method: 'POST', 
      body: JSON.stringify(data), 
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(auth_token_key)}`,
        'Content-Type': 'application/json' 
      } 
    })
    .then(response => {
      return response.blob().then(blob => {
        return {
          blob
        }
      })
    })
    .then(data => accept({ blob: data.blob }))
    .catch(error => reject(error))
  });
}

export const CreateReport = async (data) =>
  await client.post(`/process_reports`, data);

export const GetProcessReportsList = async (id, filters) =>
  await client.get(`/process_reports/${id}?filter=${filters}`);

export const DeleteProcessReport = async (id) =>
  await client.delete(`/process_reports/${id}`);

export const GetPendingPaymentsList = async (currentPage, limit, filters, sort) =>
  await client.get(`/process/pending_payments/${currentPage}/${limit}?filter=${filters}&sort=${sort}`);