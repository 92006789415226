import { TabTitleWrapper, TabTitle } from '../../styles';
import { PdfWrapper, PdfItemWrapper, PdfItem, PdfTitle, PdfButtons, PdfStatus } from './styles';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { Popconfirm, Tooltip } from 'antd';
import { getPdfMissingFields } from '../../../../infra/utils/ProcessPdf';
import { subjectsList } from '../../../../infra/utils/TemplatesSubjects';
import React, { useState } from 'react';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import PdfEmailAddressModal from '../../modals/PdfEmailAddress';
import PdfEmailModal from '../../modals/PdfEmail';
import PdfGenerateAddressModal from '../../modals/PdfGenerateAddress';
import PdfGenerateSubjectModal from '../../modals/PdfGenerateSubject';

const Pdf = ({ processInfo, addressList, user, dispatch }) => {
  const [addressModal, setAddressModal] = useState(false);
  const [subjectModal, setSubjectModal] = useState(false);
  const [pdfType, setPdfType] = useState(0);
  const [emailModal, setEmailModal] = useState(false);

  const generatePDF = (type) => {
    window.open(
      `${process.env.REACT_APP_API}/automatic_pdf/process/${user?._id}/${processInfo._id}?filter={"type":${type}}`,
      '_blank'
    );
  };

  const openAddressModal = (type) => {
    setAddressModal(true);
    setPdfType(type);
  };

  const openSubjectModal = (type) => {
    setSubjectModal(true);
    setPdfType(type);
  };

  const openEmailModal = (type) => {
    if(
      (type === 1 && !processInfo?.agent) ||
      type === 3 ||
      type === 4 ||
      type === 12
    ) {
      dispatch(initialize('pdf_email_address_form', { email: processInfo?.email || '' }));
    } 
    else {
      dispatch(initialize('pdf_email_form', { email: processInfo?.email || '' }));
    }

    setEmailModal(true);
    setPdfType(type);
  };

  const openPDF = (type) => {
    if(type === 8) {
      window.open(require('../../../../assets/pdf/type8.pdf'), '_blank');
    }
    else if(type === 9) {
      window.open(require('../../../../assets/pdf/type9.pdf'), '_blank');
    }
  };

  const renderStatus = (pdf) => {
    return (
      <PdfStatus error={pdf.error}>
        {
          pdf.error && pdf.abrevText !== pdf.fullText ?
          <Tooltip placement='bottom' title={pdf.fullText}>
            {pdf.abrevText}
          </Tooltip>
          :
          pdf.abrevText
        }
      </PdfStatus>
    );
  }

  const pdf1 = getPdfMissingFields(5, processInfo);
  const pdf2 = getPdfMissingFields(1, processInfo);
  const pdf3 = getPdfMissingFields(2, processInfo);
  const pdf4 = getPdfMissingFields(3, processInfo);
  const pdf5 = getPdfMissingFields(4, processInfo);
  const pdf6 = getPdfMissingFields(6, processInfo);
  const pdf7 = getPdfMissingFields(7, processInfo);
  const pdf8 = getPdfMissingFields(8, processInfo);
  const pdf9 = getPdfMissingFields(9, processInfo);
  const pdf10 = getPdfMissingFields(10, processInfo);
  const pdf11 = getPdfMissingFields(11, processInfo);
  const pdf12 = getPdfMissingFields(12, processInfo);

  return (
    <>
      <TabTitleWrapper>
        <TabTitle>PDF's</TabTitle>
      </TabTitleWrapper>
      <PdfWrapper>
        <PdfItemWrapper>
          <PdfItem>
            <PdfTitle>#1 | Carta de Rosto</PdfTitle>
            <PdfButtons>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza pretende gerar este PDF?'
                onConfirm={() => generatePDF(5)}
              >
                <BaseButton 
                  customIcon='download2'
                  type='black'
                  text='Gerar PDF'
                  size='small'
                />
              </Popconfirm>
            </PdfButtons>
            {renderStatus(pdf1)}
          </PdfItem>
        </PdfItemWrapper>
        <PdfItemWrapper>
          <PdfItem>
            <PdfTitle>#2 | Procuração</PdfTitle>
            <PdfButtons>
              {
                processInfo?.agent ?
                <Popconfirm
                  placement='topRight'
                  title='Tem a certeza pretende gerar este PDF?'
                  onConfirm={() => generatePDF(1)}
                >
                  <BaseButton 
                    customIcon='download2'
                    type='black'
                    text='Gerar PDF'
                    size='small'
                  />
                </Popconfirm>
                :
                <BaseButton 
                  customIcon='download2'
                  type='black'
                  size='small'
                  text='Gerar PDF' 
                  onClick={() => openAddressModal(1)} 
                />
              }
              {
                !processInfo?.archived &&
                <BaseButton
                  customIcon='mail'
                  type='grey'
                  size='small'
                  text='Enviar Email'
                  onClick={() => openEmailModal(1)}
                />
              }
            </PdfButtons>
            {renderStatus(pdf2)}
          </PdfItem>
        </PdfItemWrapper>
        <PdfItemWrapper>
          <PdfItem>
            <PdfTitle>#3 | Avoir de Recherche</PdfTitle>
            <PdfButtons>
              <Popconfirm
                placement='topRight'
                title='Tem a certeza pretende gerar este PDF?'
                onConfirm={() => generatePDF(2)}
              >
                <BaseButton 
                  customIcon='download2'
                  type='black'
                  size='small'
                  text='Gerar PDF' 
                />
              </Popconfirm>
              {
                !processInfo?.archived && user?.type !== 3 &&
                <BaseButton
                  customIcon='mail'
                  type='grey'
                  size='small'
                  text='Enviar Email'
                  onClick={() => openEmailModal(2)}
                />
              }
            </PdfButtons>
            {renderStatus(pdf3)}
          </PdfItem>
        </PdfItemWrapper>
        {
          !processInfo?.agent &&
          <>
            <PdfItemWrapper>
              <PdfItem>
                <PdfTitle>#4 | Formulário pedido decompte suppletive</PdfTitle>
                <PdfButtons>
                  <BaseButton
                    customIcon='download2'
                    type='black'
                    size='small'
                    text='Gerar PDF'
                    onClick={() => openAddressModal(3)}
                  />
                  {
                    !processInfo?.archived && user?.type !== 3 &&
                    <BaseButton
                      customIcon='mail'
                      type='grey'
                      size='small'
                      text='Enviar Email'
                      onClick={() => openEmailModal(3)}
                    />
                  }
                </PdfButtons>
                {renderStatus(pdf4)}
              </PdfItem>
            </PdfItemWrapper>
            <PdfItemWrapper>
              <PdfItem>
                <PdfTitle>#5 | Caisse de Pension Pro</PdfTitle>
                <PdfButtons>
                  <BaseButton
                    customIcon='download2'
                    type='black'
                    size='small'
                    text='Gerar PDF'
                    onClick={() => openAddressModal(4)}
                  />
                  {
                    !processInfo?.archived && user?.type !== 3 &&
                    <BaseButton
                      customIcon='mail'
                      type='grey'
                      size='small'
                      text='Enviar Email'
                      onClick={() => openEmailModal(4)}
                    />
                  }
                </PdfButtons>
                {renderStatus(pdf5)}
              </PdfItem>
            </PdfItemWrapper>
            <PdfItemWrapper>
              <PdfItem>
                <PdfTitle>#6 | Formulário afiliação segurança social</PdfTitle>
                <PdfButtons>
                  <Popconfirm
                    placement='topRight'
                    title='Tem a certeza pretende gerar este PDF?'
                    onConfirm={() => generatePDF(6)}
                  >
                    <BaseButton 
                      customIcon='download2'
                      type='black'
                      size='small'
                      text='Gerar PDF' 
                    />
                  </Popconfirm>
                  {
                    !processInfo?.archived &&
                    <BaseButton
                      customIcon='mail'
                      type='grey'
                      size='small'
                      text='Enviar Email'
                      onClick={() => openEmailModal(6)}
                    />
                  }
                </PdfButtons>
                {renderStatus(pdf6)}
              </PdfItem>
            </PdfItemWrapper>
            <PdfItemWrapper>
              <PdfItem>
                <PdfTitle>#7 | FR formulaire extrait du compte AVS</PdfTitle>
                <PdfButtons>
                  <Popconfirm
                    placement='topRight'
                    title='Tem a certeza pretende gerar este PDF?'
                    // onConfirm={() => openPDF(7)}
                    onConfirm={() => generatePDF(7)}
                  >
                    <BaseButton 
                      customIcon='download2'
                      type='black'
                      size='small'
                      text='Gerar PDF' 
                    />
                  </Popconfirm>
                  {
                    !processInfo?.archived && user?.type !== 3 &&
                    <BaseButton
                      customIcon='mail'
                      type='grey'
                      size='small'
                      text='Enviar Email'
                      onClick={() => openEmailModal(7)}
                    />
                  }
                </PdfButtons>
                {renderStatus(pdf7)}
              </PdfItem>
            </PdfItemWrapper>
            <PdfItemWrapper>
              <PdfItem>
                <PdfTitle>#8 | Pedido Reforma AVS Fora Suiça</PdfTitle>
                <PdfButtons>
                  <Popconfirm
                    placement='topRight'
                    title='Tem a certeza pretende gerar este PDF?'
                    onConfirm={() => openPDF(8)}
                  >
                    <BaseButton 
                      customIcon='download2'
                      type='black'
                      size='small'
                      text='Gerar PDF' 
                    />
                  </Popconfirm>
                  {
                    !processInfo?.archived && user?.type !== 3 &&
                    <BaseButton
                      customIcon='mail'
                      type='grey'
                      size='small'
                      text='Enviar Email'
                      onClick={() => openEmailModal(8)}
                    />
                  }
                </PdfButtons>
                {renderStatus(pdf8)}
              </PdfItem>
            </PdfItemWrapper>
            <PdfItemWrapper>
              <PdfItem>
                <PdfTitle>#9 | Pedido Reforma AVS Na Suiça</PdfTitle>
                <PdfButtons>
                  <Popconfirm
                    placement='topRight'
                    title='Tem a certeza pretende gerar este PDF?'
                    onConfirm={() => openPDF(9)}
                  >
                    <BaseButton 
                      customIcon='download2'
                      type='black'
                      size='small'
                      text='Gerar PDF' 
                    />
                  </Popconfirm>
                  {
                    !processInfo?.archived && user?.type !== 3 &&
                    <BaseButton
                      customIcon='mail'
                      type='grey'
                      size='small'
                      text='Enviar Email'
                      onClick={() => openEmailModal(9)}
                    />
                  }
                </PdfButtons>
                {renderStatus(pdf9)}
              </PdfItem>
            </PdfItemWrapper>
            <PdfItemWrapper>
              <PdfItem>
                <PdfTitle>#10 | Aviso de Pagamento</PdfTitle>
                <PdfButtons>
                  <BaseButton
                    customIcon='download2'
                    type='black'
                    size='small'
                    text='Gerar PDF'
                    onClick={() => openSubjectModal(10)}
                  />
                </PdfButtons>
                {renderStatus(pdf10)}
              </PdfItem>
            </PdfItemWrapper>
            <PdfItemWrapper>
              <PdfItem>
                <PdfTitle>#11 | Lemania Cashout</PdfTitle>
                <PdfButtons>
                  <Popconfirm
                    placement='topRight'
                    title='Tem a certeza pretende gerar este PDF?'
                    onConfirm={() => generatePDF(11)}
                  >
                    <BaseButton
                      customIcon='download2'
                      type='black'
                      size='small'
                      text='Gerar PDF' 
                    />
                  </Popconfirm>
                  {
                    !processInfo?.archived && user?.type !== 3 &&
                    <BaseButton
                      customIcon='mail'                      
                      type='grey'
                      size='small'
                      text='Enviar Email'
                      onClick={() => openEmailModal(11)}
                    />
                  }
                </PdfButtons>
                {renderStatus(pdf11)}
              </PdfItem>
            </PdfItemWrapper>
            <PdfItemWrapper>
              <PdfItem>
                <PdfTitle>#12 | Carta de Rosto - Empresas</PdfTitle>
                <PdfButtons>
                  <BaseButton
                    customIcon='download2'
                    type='black'
                    size='small'
                    text='Gerar PDF'
                    onClick={() => openAddressModal(12)}
                  />
                  {
                    !processInfo?.archived && user?.type !== 3 &&
                    <BaseButton
                      customIcon='mail'
                      type='grey'
                      size='small'
                      text='Enviar Email'
                      onClick={() => openEmailModal(12)}
                    />
                  }
                </PdfButtons>
                {renderStatus(pdf12)}
              </PdfItem>
            </PdfItemWrapper>
          </>
        }
      </PdfWrapper>
      <PdfGenerateAddressModal
        openModal={addressModal}
        closeModal={() => setAddressModal(false)}
        processID={processInfo?._id}
        addressList={addressList}
        pdfType={pdfType}
        user={user}
      />
      <PdfGenerateSubjectModal
        openModal={subjectModal}
        closeModal={() => setSubjectModal(false)}
        processID={processInfo?._id}
        pdfType={pdfType}
        subjectsList={subjectsList}
        user={user}
      />
      {
        ((pdfType === 1 && !processInfo?.agent) ||
        pdfType === 3 ||
        pdfType === 4 ||
        pdfType === 12) ? 
        <PdfEmailAddressModal
          openModal={emailModal}
          closeModal={() => setEmailModal(false)}
          processInfo={processInfo}
          addressList={addressList}
          pdfType={pdfType}
        />
        :
        <PdfEmailModal
          openModal={emailModal}
          closeModal={() => setEmailModal(false)}
          processInfo={processInfo}
          pdfType={pdfType}
        />
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Pdf);
