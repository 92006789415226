import { Icon } from 'antd';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { getCurrency } from '../../../../infra/utils/Currencies';
import { Row, InfoColumn, FieldWrapper, FieldTitle, FieldValue, ButtonsRow } from './styles';
import { HeaderWrapper, HeaderContainer, HeaderRow, HeaderTitle } from '../styles';
import { CardWrapper, Card, CardContainer, MissingLabel } from '../../styles';
import { getServiceMode } from '../../../../infra/utils/ServiceModes';
import { getPaymentMethod } from '../../../../infra/utils/PaymentMethods';
import { renderPaymentState } from '../../../../infra/utils/ProcessStates';
import { ReactSVG } from 'react-svg';
import React, { useState } from 'react';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import moment from 'moment-timezone';
import EditProcess from './EditProcess';
import PaymentMethod from './PaymentMethod';

const ViewProcess = ({ processInfo, responsibles, returnList, getInfo, dispatch, user }) => {
  const [editPage, setEditPage] = useState(false);

  const openEditPage = () => {
    dispatch(initialize('edit_process_payment_form', { ...processInfo, responsible: processInfo.responsible?._id }));

    setEditPage(true);
  }

  return (
    <>
      <HeaderWrapper style={{ display: 'flex', paddingLeft: '0px', paddingRight: '0px' }}>
        <HeaderContainer onClick={returnList}>
          <HeaderRow>
            <Icon type='arrow-left' style={{ fontSize: '15px', color: '#000000' }} />
            <HeaderTitle>Pagamento - {processInfo.service_name}</HeaderTitle>
          </HeaderRow>
        </HeaderContainer>
      </HeaderWrapper>
      <CardWrapper gutter={24} style={{ marginTop: '15px' }}>
        <Card xs={24}>
          <CardContainer>
            {
              editPage ?
              <EditProcess 
                processInfo={processInfo}
                responsibles={responsibles}
                returnDetail={() => setEditPage(false)}
                getInfo={getInfo}
              />
              :
              <>
                <ButtonsRow>
                  {
                    !processInfo.archived && user?.type !== 3 &&
                    <BaseButton
                      type={'secondary'}
                      icon='edit'
                      text={'Editar'}
                      onClick={() => openEditPage()}
                    />
                  }
                </ButtonsRow>
                <Row>
                  <InfoColumn>
                    <FieldWrapper>
                      <FieldTitle>Data de Compra:</FieldTitle>
                      <FieldValue>{moment(processInfo.purchase_date).format('DD/MM/YYYY, HH:mm')}h</FieldValue>
                    </FieldWrapper>
                    <FieldWrapper>
                      <FieldTitle>Serviço Adquirido:</FieldTitle>
                      <FieldValue>{processInfo.service_name}</FieldValue>
                    </FieldWrapper>
                    <FieldWrapper>
                      <FieldTitle>Modalidade do Serviço:</FieldTitle>
                      <FieldValue>{getServiceMode(processInfo.service_mode)}</FieldValue>
                    </FieldWrapper>
                    {
                      user?.type === 1 &&
                      <FieldWrapper>
                        <FieldTitle>Responsável:</FieldTitle>
                        <FieldValue>
                          {
                            processInfo?.responsible ? 
                            processInfo.responsible.name : 
                            <MissingLabel>
                              <ReactSVG src={`${process.env.REACT_APP_BO_URL}warning.svg`} />
                              <span>Sem Responsável</span>
                            </MissingLabel>
                          }
                        </FieldValue>
                      </FieldWrapper>
                    }
                    {
                      !processInfo.free &&
                      <FieldWrapper>
                        <FieldTitle>Método de Pagamento:</FieldTitle>
                        <FieldValue>
                          {getPaymentMethod(processInfo.payment_method)}
                        </FieldValue>
                      </FieldWrapper>
                    }
                    <FieldWrapper>
                      <FieldTitle>Estado de Pagamento:</FieldTitle>
                      <FieldValue>
                        {
                          processInfo.state_payment ?
                          renderPaymentState(processInfo.state_payment)
                          :
                          <MissingLabel>
                            <ReactSVG src={`${process.env.REACT_APP_BO_URL}warning.svg`} />
                            <span>Sem estado de pagamento</span>
                          </MissingLabel>
                        }
                      </FieldValue>
                    </FieldWrapper>
                  </InfoColumn>
                  {
                    user.type === 1 &&
                    <InfoColumn>
                      {
                        processInfo.front ?
                        <>
                          <FieldWrapper>
                            <FieldTitle>Valor base:</FieldTitle>
                            <FieldValue>
                              {
                                processInfo.free ? 
                                'Gratuito' 
                                : 
                                `${processInfo.price_base}${getCurrency(processInfo.currency)}`
                              }
                            </FieldValue>
                          </FieldWrapper>
                          <FieldWrapper>
                            <FieldTitle>Valor extra:</FieldTitle>
                            <FieldValue>
                              {
                                processInfo.free ? 
                                'Gratuito' 
                                : 
                                `+ ${processInfo.price_extra}${getCurrency(processInfo.currency)}`
                              }
                            </FieldValue>
                          </FieldWrapper>
                          {
                            !processInfo.free &&
                            <FieldWrapper>
                              <FieldTitle>Promocode:</FieldTitle>
                              <FieldValue>{processInfo.cod_promo_discount > 0 ? `- ${processInfo.cod_promo_discount}${getCurrency(processInfo.currency)}` : '-'}</FieldValue>
                            </FieldWrapper>
                          }
                          <FieldWrapper>
                            <FieldTitle>Total:</FieldTitle>
                            <FieldValue big={true}>
                              {
                                processInfo.free ? 
                                'Gratuito' 
                                : 
                                `${processInfo.price}${getCurrency(processInfo.currency)}`
                              }
                            </FieldValue>
                          </FieldWrapper>
                        </>
                        :
                        <FieldWrapper>
                          <FieldTitle width='30%'>Total:</FieldTitle>
                          <FieldValue big={true}>
                            {
                              processInfo.free ? 
                              'Gratuito' 
                              : 
                              `${processInfo.price}${getCurrency(processInfo.currency)}`
                            }
                          </FieldValue>
                        </FieldWrapper>
                      }
                    </InfoColumn>
                  }
                </Row>
              </>
            }
          </CardContainer>
        </Card>
      </CardWrapper> 
      {
        (processInfo.payment_method === 2 || processInfo.payment_method === 4) &&
        <PaymentMethod
          processInfo={processInfo}
          getInfo={getInfo}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(ViewProcess);
