/* eslint-disable react-hooks/exhaustive-deps */
import { TabTitleWrapper, TabButtonsWrapper, TabTitle } from '../../styles';
import { GetProcessPayments, DeletePayment  } from '../../../../infra/requests/ProcessRequests';
import { notification } from 'antd';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import { getPaymentMethod } from '../../../../infra/utils/PaymentMethods';
import { renderPaymentState } from '../../../../infra/utils/ProcessStates';
import { TableWrapper, TotalRow, EmptyColumn, TextColumn, TotalColumn } from './styles';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import Table from '../../../../components/generic/table/Table';
import Filters from './Filters';
import NewPaymentModal from '../../modals/NewPayment';
import EditPaymentModal from '../../modals/EditPayment';
import CurrencyComponent from '../../../../components/generic/currency/CurrencyComponent';
import ButtonActions from '../../../../components/generic/buttons/ButtonActions';
import ViewProcess from './ViewProcess';

const Payments = ({ processInfo, responsibles, getInfo, reloadTab, users, dispatch, user }) => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState('');
  const [rows, setRows] = useState([]);
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [paymentModalInfo, setPaymentModalInfo] = useState(null);
  const [viewDetail, setViewDetail] = useState(false);

  useEffect(() => {
    const init = async () => await getPaymentsInfo();

    init();
  }, [filters, reloadTab]);

  const getPaymentsInfo = async () => {
    setLoading(true);

    try {
      const result = await GetProcessPayments(processInfo._id, filters);
      const paymentsList = result?.data || [];

      // If the Process was not originated from an Agent, add the first line of the payment with the Service of the Process
      if(!processInfo.agent) {
        let isValid = true;

        // We need to apply the selected filters to the original line (if there are any)
        if(filters) {
          const filtersObj = JSON.parse(filters);

          if(filtersObj.search) {
            const regex = new RegExp(filtersObj.search, 'i');
            
            if(!regex.test(processInfo.service_name)) isValid = false;
          }
    
          if(filtersObj.date) {
            const date = moment(processInfo.purchase_date);
            const start = moment(filtersObj.date).startOf('day');
            const end = moment(filtersObj.date).endOf('day');

            if(!date.isBetween(start, end)) isValid = false;
          }

          if(filtersObj.user) isValid = false;

          if(filtersObj.payment_method && parseInt(filtersObj.payment_method) !== processInfo.payment_method) isValid = false;

          if(filtersObj.payment_state && parseInt(filtersObj.payment_state) !== processInfo.state_payment) isValid = false;
        }

        if(isValid) {
          paymentsList.push({
            _id: processInfo._id,
            amount: processInfo.price,
            currency: processInfo.currency,
            date: processInfo.purchase_date,
            description: processInfo.service_name,
            payment_method: processInfo.payment_method,
            payment_state: processInfo.state_payment,
            isMainPayment: true,
            free: processInfo.free
          });
        }
      }
    
      setRows(paymentsList);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
  };

  const openEditModal = (data) => {
    dispatch(initialize('edit_payment_form', data));

    setEditModal(true);
    setPaymentModalInfo(data);
  }

  const removePayment = async (data) => {
    try {
      setLoading(true);

      await DeletePayment (data._id);
      await getInfo();

      notification.success({
        message: 'Pagamento removido com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getTotalProcessValue = () => {
    let pendingValue = '-', pendingValuesCHF = 0, pendingValuesEuro = 0;

    if(rows?.length > 0) {
      rows.forEach(row => {
        if(!row.free && (user?.type !== 3 || !row.isMainPayment)) {
          if(row.currency === 1) pendingValuesCHF += row.amount;
          else pendingValuesEuro += row.amount;
        }
      });
    }

    if(pendingValuesCHF > 0 && pendingValuesEuro > 0) pendingValue = `${pendingValuesCHF}CHF + ${pendingValuesEuro}€`;
    else if(pendingValuesCHF > 0) pendingValue = `${pendingValuesCHF}CHF`;
    else if(pendingValuesEuro > 0) pendingValue = `${pendingValuesEuro}€`;

    return pendingValue;
  };


  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      render: (value) => `${moment(value).format('DD-MM-YYYY')}`,
      width: '10%'
    },
    {
      title: 'Utilizador',
      dataIndex: 'user',
      render: (value) => value?.name || '',
      width: '15%'
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      render: (value) => value,
      width: '24%'
    },
    {
      title: 'Método de pagamento',
      dataIndex: 'payment_method',
      render: (value) => getPaymentMethod(value),
      width: '16%'
    },
    {
      title: 'Montante',
      key: 'amount',
      width: '11%',
      render: (data) =>  
        <>
          {
            data.free ?
            'Gratuito'
            :
            (data.isMainPayment && user?.type !== 3) || !data.isMainPayment ?
            <CurrencyComponent
              value={data.amount}
              suffix={
                data.currency === 1 ? 'CHF' : data.currency === 2 ? '€' : ''
              }
            />
            :
            '-'
          }
        </>
    },
    {
      title: 'Estado do pagamento',
      dataIndex: 'payment_state',
      render: (value) => renderPaymentState(value),
      width: '16%'
    },
    {
      title: 'Ações',
      render: (data) => {
        const options = [];

        if(data.isMainPayment) {
          options.push({
            onClick: () => setViewDetail(true),
            label: 'Ver detalhe' 
          });
        }
        else if(!processInfo.archived) {
          options.push({
            onClick: () => openEditModal(data),
            label: 'Editar' 
          });

          if(user?.type !== 3) {
            options.push({
              popConfirmtitle: 'Tem a certeza que quer remover este pagamento?',
              onClick: () => removePayment(data),
              label: 'Remover' 
            });
          }
        }

        return (
          <>
            {
              options.length > 0 &&
              <ButtonActions 
                icon={'dots'} 
                iconType={'fill'} 
                options={options} 
              />
            }
          </>
        );
      }
    }
  ];

  return (
    <>
      {
        viewDetail ?
        <ViewProcess
          processInfo={processInfo}
          responsibles={responsibles}
          returnList={() => setViewDetail(false)}
          getInfo={getInfo}
        />
        :
        <>
          <TabTitleWrapper>
            <TabTitle>Histórico de Pagamentos</TabTitle>
            <TabButtonsWrapper>
              {
                !processInfo.archived &&
                <BaseButton
                  type={'primary'}
                  text={'Adicionar pagamento'}
                  onClick={() => setNewModal(true)}
                />
              }
            </TabButtonsWrapper>
          </TabTitleWrapper>
          <Filters
            queryChange={handleFilterChange}
            users={users}
          />
          <TableWrapper hasTotal={true}>
            <Table
              columns={columns}
              loading={loading}
              rows={rows}
              showHeader={true}
              emptyIcon='warning'
              emptyText={'Não existem Pagamentos inseridos!'}
              rowKey={'_id'}
              hasPagination={false}
            />
            <TotalRow>
              <EmptyColumn width='49%'></EmptyColumn>
              <TextColumn width='16%'>Total:</TextColumn>
              <TotalColumn width='11%'>
                {getTotalProcessValue()}
              </TotalColumn>
              <EmptyColumn width='16%'></EmptyColumn>
            </TotalRow>
          </TableWrapper>
          {
            !processInfo.archived &&
            <>
              <NewPaymentModal
                openModal={newModal}
                closeModal={() => setNewModal(false)}
                getInfo={getInfo}
                processID={processInfo._id}
              />
              <EditPaymentModal
                openModal={editModal}
                closeModal={() => setEditModal(false)}
                getInfo={getInfo}
                paymentModalInfo={paymentModalInfo}
              />
            </>
          }
        </>
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Payments);
