
import { Note, TitleModal, SubtitleModal, NewFileContainer, OrTitle, TemplateFileNote, TotalFilesSelected, FilesSelected, File, FilenameColumn, Filename, Filesize, RejectedFiles, RejectedAlert } from './styles';
import { Field } from 'redux-form';
import { subjectsList } from '../../../../infra/utils/TemplatesSubjects';
import { ReactSVG } from 'react-svg';
import { Tooltip } from 'antd';
import { getPdfMissingFields } from '../../../../infra/utils/ProcessPdf';
import React from 'react';
import SelectInput from '../../../../components/generic/inputs/SelectInput';
import Dropzone from './MultipleDropzone';

const templates = [
  {
    type: 5,
    name: 'Carta de Rosto',
    address: false,
    subject: false,
    showAgent: true
  },
  {
    type: 1,
    name: 'Procuração',
    address: true,
    subject: false,
    showAgent: true
  },
  {
    type: 2,
    name: 'Avoir de Recherche',
    address: false,
    subject: false,
    showAgent: true
  },
  {
    type: 3,
    name: 'Formulário pedido decompte suppletive',
    address: true,
    subject: false,
    showAgent: false
  },
  {
    type: 4,
    name: 'Caisse de Pension Pro',
    address: true,
    subject: false,
    showAgent: false
  },
  {
    type: 6,
    name: 'Formulário afiliação segurança social',
    address: false,
    subject: false,
    showAgent: false
  },
  {
    type: 7,
    name: 'FR formulaire extrait du compte AVS',
    address: false,
    subject: false,
    showAgent: false
  },
  {
    type: 8,
    name: 'Pedido Reforma AVS Fora Suiça',
    address: false,
    subject: false,
    showAgent: false
  },
  {
    type: 9,
    name: 'Pedido Reforma AVS Na Suiça',
    address: false,
    subject: false,
    showAgent: false
  },
  {
    type: 10,
    name: 'Aviso de Pagamento',
    address: false,
    subject: true,
    showAgent: false
  },
  {
    type: 11,
    name: 'Lemania Cashout',
    address: false,
    subject: true,
    showAgent: false
  },
  {
    type: 12,
    name: 'Carta de Rosto - Empresas',
    address: false,
    subject: true,
    showAgent: false
  }
];

const getTemplate = (type) => templates.find(elem => elem.type === parseInt(type));

const FileUpload = ({ 
  addressList, 
  checkSelectedTemplate, 
  checkSelectedAddress, 
  checkSelectedSubject,
  checkSelectedDocument,
  checkSelectedReport,
  addressSelect, 
  subjectSelect, 
  acceptedFiles, 
  setAcceptedFiles, 
  rejectedFiles, 
  setRejectedFiles,
  documents,
  reports,
  processInfo,
  addressError,
  subjectError,
  user
}) => {
  const removeFile = (index) => {
    const aux = [...acceptedFiles];
    aux.splice(index, 1);
    setAcceptedFiles([...aux]);
  }

  const templatesList = user?.type === 2 ? templates.filter(elem => elem.showAgent) : templates;

  return (
    <>
      <TitleModal>Ficheiros</TitleModal>
      <SubtitleModal>
        Pode anexar vários ficheiros!<br/>
        Se selecionar da lista de "PDF" ou "Documentos", deve selecionar um de cada vez.
        Caso pretenda fazer upload, pode selecionar vários ao mesmo tempo.
      </SubtitleModal>
      <NewFileContainer>
        <Field
          component={SelectInput}
          label={'Documento'}
          placeholder={'Selecione um documento'}
          name={'document'}
          type='select'
          data={documents}
          dataKey={'_id'}
          dataLabel={'name'}
          allowClear={true}
          execAfterChange={checkSelectedDocument}
        />
        <OrTitle>ou</OrTitle>
        <Field
          component={SelectInput}
          label={'Relatório'}
          placeholder={'Selecione um relatório'}
          name={'report'}
          type='select'
          data={reports}
          dataKey={'_id'}
          dataLabel={'name'}
          allowClear={true}
          execAfterChange={checkSelectedReport}
        />
        <OrTitle>ou</OrTitle>
        <Field
          component={SelectInput}
          label={'PDF'}
          placeholder={'Selecione um pdf'}
          name={'file_template'}
          type='select'
          data={templatesList}
          dataKey={'type'}
          dataLabel={'name'}
          allowClear={true}
          execAfterChange={checkSelectedTemplate}
        />
        {
          addressSelect ?
          <React.Fragment>
            <TemplateFileNote hasError={addressError}>Selecione uma morada para completar o PDF</TemplateFileNote>
            <Field
              component={SelectInput}
              placeholder={'Selecione uma morada *'}
              name={'address'}
              type='select'
              data={addressList}
              dataKey={'_id'}
              dataLabel={'name'}
              allowClear={true}
              first={true}
              execAfterChange={checkSelectedAddress}
              hasError={addressError}
            />
          </React.Fragment>
          :
          subjectSelect ?
          <React.Fragment>
            <TemplateFileNote hasError={subjectError}>Selecione um assunto para completar o PDF</TemplateFileNote>
            <Field
              component={SelectInput}
              placeholder={'Selecione um assunto *'}
              name={'subject'}
              type='select'
              data={subjectsList}
              dataKey={'value'}
              dataLabel={'label'}
              allowClear={true}
              first={true}
              execAfterChange={checkSelectedSubject}
              hasError={subjectError}
            />
          </React.Fragment>
          : 
          null
        }
        <OrTitle>ou</OrTitle>
        <Field 
          name='file' 
          component={Dropzone}
          handleAcceptedFiles={(files) => setAcceptedFiles([ ...acceptedFiles, ...files ])}
          handleRejectedFiles={(errors) => setRejectedFiles([ ...rejectedFiles, ...errors ])}
        />
      </NewFileContainer>
      {
        rejectedFiles?.length > 0 &&
        <RejectedFiles>
          {
            rejectedFiles.map((error, index) =>
              <RejectedAlert
                key={index}
                description={error}
                type='error'
                closable
              />
            )
          }
        </RejectedFiles>
      }
      <TotalFilesSelected>{acceptedFiles.length} ficheiros anexados</TotalFilesSelected>
      {
        acceptedFiles.length > 0 &&
        <>
          <FilesSelected>
            {
              acceptedFiles.map((file, index) => {
                const pdf = getPdfMissingFields(parseInt(file.file_template), processInfo);

                return (
                  <File key={index}>
                    <FilenameColumn>
                      <div style={{ width: '28px' }}>
                        {
                          file.file_template && pdf.error &&
                          <Tooltip placement='bottom' title={pdf.fullText}>
                            <ReactSVG 
                              src={`${process.env.REACT_APP_BO_URL}warning.svg`} 
                              className='alertIcon' 
                            />
                          </Tooltip>
                        }
                      </div>
                      <Filename>
                        {
                          file.file_template ?
                          getTemplate(file.file_template)?.name
                          :
                          file.document || file.report ?
                          file.filename
                          :
                          file.blob?.name
                        }
                      </Filename>
                      {
                        file.size > 0 &&
                        <Filesize>
                          ({(file.size / (1024 * 1024)).toFixed(2)}MB)
                        </Filesize>
                      }
                    </FilenameColumn>
                    <ReactSVG 
                      src={`${process.env.REACT_APP_BO_URL}close_circle.svg`} 
                      className='icon' 
                      onClick={() => removeFile(index)} 
                    />
                  </File>
                );
              })
            }
          </FilesSelected>
          <Note><span>Nota: </span>Atenção ao tamanho dos ficheiros anexados! Pode ultrapassar o limite de envio dos emails.</Note>
        </>
      }
    </>
  );
};

export default FileUpload;
