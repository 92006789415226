/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from 'react-redux';
import { Popconfirm, notification } from 'antd';
import { SpinLoading } from '../../../../styles/BasicStyles';
import { UpdatesWrapper, UpdateItem, UpdateContainer, UpdateCol, UpdateName, UpdateDate, UpdateText, UpdateActions, UpdateFilesList, UpdateFile, UpdatesSeeMore, NoUpdates } from '../styles';
import { TabTitleWrapper, TabTitle } from '../../styles';
import { ReactSVG } from 'react-svg';
import { GetProcessUpdates, DeleteProcessUpdate, GetProcessDocuments, GetProcessReportsList } from '../../../../infra/requests/ProcessRequests';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import GetFile from '../../../../infra/utils/GetFile';
import NewUpdateModal from '../../modals/NewUpdate';

const perPage = 4;

const Updates = ({ processInfo, addressList, reloadTab, user }) => {
  const [updates, setUpdates] = useState([]);
  const [totalUpdates, setTotalUpdates] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(perPage);
  const [ready, setReady] = useState(false);
  const [newUpdate, setNewUpdate] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, [reloadTab]);

  const getInfo = async () => {
    try {
      const updatesList = await GetProcessUpdates(processInfo._id);
      const documentsList = await GetProcessDocuments(processInfo._id);
      const reportsList = user?.type === 1 ? await GetProcessReportsList(processInfo._id, '') : [];

      const finalDocumentsList = [];
      if(documentsList?.data?.length > 0) {
        documentsList.data.forEach(elem => {
          finalDocumentsList.push({
            _id: elem.file._id,
            name: elem.file.filename,
            size: elem.file.length
          });
        });
      }

      const finalReportsList = [];
      if(reportsList?.data?.length > 0) {
        reportsList.data.forEach(elem => {
          finalReportsList.push({
            _id: elem.file._id,
            name: elem.file.filename,
            size: elem.file.length
          });
        });
      }

      setUpdates(updatesList?.data || []);
      setTotalUpdates(updatesList?.data?.length || 0);
      setDocuments(finalDocumentsList);
      setReports(finalReportsList);
      setReady(true);
    }
    catch(error) {
      console.log(error);
    }
  }

  const deleteUpdate = async (id) => {
    try {
      const result = await DeleteProcessUpdate(id);

      if(result.success) {
        notification.success({
          message: 'Atualização eliminada com sucesso!'
        });

        await getInfo();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  if(!ready) return <SpinLoading />;

  return (
    <>
      <TabTitleWrapper>
        <TabTitle>Atualizações</TabTitle>
        {
          !processInfo.archived && user?.type === 1 &&
          <BaseButton
            type={'primary'}
            icon={'plus'}
            text={'Criar atualização'}
            onClick={() => setNewUpdate(true)}
          />
        }
      </TabTitleWrapper>
      {
        updates.length > 0 ?
        <>
          <UpdatesWrapper>
            {
              updates.slice(0, currentOffset).map((update, index) =>
              <UpdateItem key={index}>
                <UpdateContainer>
                  <UpdateCol>
                    <UpdateName>
                      {
                        update.origin === 1 ?
                        'Administrador'
                        :
                        'Cliente'
                      }
                    </UpdateName>
                    {
                      update.origin === 1 && update.admin?.name &&
                      <UpdateName>
                        {update.admin.name}
                      </UpdateName>
                    }
                    <UpdateDate>{moment(update.date).format('DD/MM/YYYY HH:mm')}h</UpdateDate>
                  </UpdateCol>
                  <UpdateCol>
                    <UpdateText>{update.message}</UpdateText>
                    <UpdateActions>
                      <UpdateFilesList>
                        {
                          // Old BO: only allowed 1 file
                          update.file && update.file_selected ?
                          <UpdateFile onClick={() => GetFile(update.file_selected._id)}>
                            <ReactSVG src={`${process.env.REACT_APP_BO_URL}file.svg`} className='icon' />
                            <span>{update.file_selected.filename}</span>
                          </UpdateFile>
                          :
                          update.upload_file ?
                          <UpdateFile onClick={() => GetFile(update.upload_file._id)}>
                            <ReactSVG src={`${process.env.REACT_APP_BO_URL}file.svg`} className='icon' />
                            <span>{update.upload_file.filename}</span>
                          </UpdateFile>
                          :
                          null
                        }
                        {
                          // New BO: allows multiple files
                          update.files?.length > 0 &&
                          update.files.map((file, index) =>
                            <UpdateFile 
                              key={index}
                              onClick={() => GetFile(file._id)} 
                              style={{ marginTop: index > 0 ? '5px' : '0px' }}
                            >
                              <ReactSVG src={`${process.env.REACT_APP_BO_URL}file.svg`} className='icon' />
                              <span>{file.filename}</span>
                            </UpdateFile>
                          )
                        }
                      </UpdateFilesList>
                      {
                        !processInfo.archived && user?.type === 1 &&
                        <Popconfirm
                          placement='topRight'
                          title='Tem a certeza que quer remover esta actualização?'
                          onConfirm={() => deleteUpdate(update._id)}
                        >
                          <ReactSVG src={`${process.env.REACT_APP_BO_URL}trash.svg`} className='icon' />
                        </Popconfirm>
                      }
                    </UpdateActions>
                  </UpdateCol>
                </UpdateContainer>
              </UpdateItem>
            )}
          </UpdatesWrapper>
          {
            totalUpdates > perPage && currentOffset < totalUpdates && 
            <UpdatesSeeMore>
              <BaseButton
                type={'secondary'}
                text={'Ver mais'}
                onClick={() => setCurrentOffset(currentOffset + perPage)}
              />
            </UpdatesSeeMore>
          }
        </>
        :
        <NoUpdates>Não existem atualizações inseridas!</NoUpdates>
      }
      {
        !processInfo.archived &&
        <NewUpdateModal
          open={newUpdate}
          closeModal={() => setNewUpdate(false)}
          processInfo={processInfo}
          addressList={addressList}
          getInfo={getInfo}
          documents={documents}
          reports={reports}
        />
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Updates);
