/* eslint-disable react-hooks/exhaustive-deps */
import { CardWrapper, Card, CardContainer, TabTitleWrapper, TabButtonsWrapper, TabTitle } from '../../styles';
import { Separator } from '../../../../styles/BasicStyles';
import { GetProcessNotes, DeleteNote } from '../../../../infra/requests/ProcessRequests';
import { notification } from 'antd';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import Table from '../../../../components/generic/table/Table';
import Filters from './Filters';
import NewNoteModal from '../../modals/NewNote';
import EditNoteModal from '../../modals/EditNote';
import ButtonActions from '../../../../components/generic/buttons/ButtonActions';

const Notes = ({ processInfo, reloadTab, users, dispatch, user }) => {
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState('');
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const columns = [
    {
      title: 'Data',
      dataIndex: 'createdAt',
      render: (value) => `${moment(value).format('DD-MM-YYYY, HH:mm')}h`,
      width: '20%'
    },
    {
      title: 'Utilizador',
      dataIndex: 'user',
      render: (value) => value.name,
      width: '25%'
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      render: (value) => <div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>,
      width: '45%'
    },
    {
      title: 'Ações',
      render: (data) => {
        const options = [];

        if(!processInfo.archived) {
          options.push({
            onClick: () => openEditModal(data),
            label: 'Editar' 
          });
        }

        if(!processInfo.archived && user?.type !== 3) {
          options.push({
            popConfirmtitle: 'Tem a certeza que quer remover esta nota?',
            onClick: () => removeNote(data),
            label: 'Remover' 
          });
        }

        return (
          <>
            {
              options.length > 0 &&
              <ButtonActions 
                icon={'dots'} 
                iconType={'fill'} 
                options={options} 
              />
            }
          </>
        );
      }
    }
  ];

  useEffect(() => {
    const init = async () => await getInfo();

    init();
  }, [filters, currentPage, pageSize, reloadTab]);

  const getInfo = async () => {
    setLoading(true);

    try {
      const result = await GetProcessNotes(processInfo._id, currentPage, pageSize, filters);

      setRows(result?.data?.items || []);
      setTotal(result?.data?.total || 0);
      setLoading(false);
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const handleFilterChange = (filters) => {
    setFilters(filters);
    setCurrentPage(1);
  };

  const handleChangePage = (currentPage) => {    
    setCurrentPage(currentPage);
  };

  const handleChangeRowsPerPage = (currentSize, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const openEditModal = (data) => {
    dispatch(initialize('edit_note_form', data));

    setEditModal(true);
  }

  const removeNote = async (data) => {
    try {
      setLoading(true);

      await DeleteNote(data._id);
      await getInfo();

      notification.success({
        message: 'Nota removida com sucesso!'
      });
    } 
    catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <>
      {
        processInfo.notes &&
        <>
          <CardWrapper gutter={24}>
            <Card xs={24} style={{ paddingTop: '15px', paddingBottom: '15px' }}>
              <CardContainer style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                <div style={{ fontWeight: 700, paddingBottom: '8px' }}>Notas internas (backoffice antigo):</div>
                {processInfo.notes}
              </CardContainer>
            </Card>
          </CardWrapper>
          <Separator />
        </>
      }
      <TabTitleWrapper>
        <TabTitle></TabTitle>
        <TabButtonsWrapper>
          {
            !processInfo.archived &&
            <BaseButton
              customIcon='plus'
              text={'Adicionar nota'}
              onClick={() => setNewModal(true)}
            />
          }
        </TabButtonsWrapper>
      </TabTitleWrapper>
      <Filters
        queryChange={handleFilterChange}
        users={users}
      />
      <Table
        columns={columns}
        currentPage={currentPage}
        pageSize={pageSize}
        loading={loading}
        rows={rows}
        showHeader={true}
        emptyIcon='warning'
        emptyText={'Não existem Notas inseridas!'}
        total={total}
        rowKey={'_id'}
        hasPagination={true}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {
        !processInfo.archived &&
        <>
          <NewNoteModal
            openModal={newModal}
            closeModal={() => setNewModal(false)}
            getInfo={getInfo}
            processID={processInfo._id}
          />
          <EditNoteModal
            openModal={editModal}
            closeModal={() => setEditModal(false)}
            getInfo={getInfo}
          />
        </>
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(Notes);
