export const getPdfMissingFields = (type, processInfo) => {
  let res = '';

  if(type === 1) {
    if(!processInfo?.first_name) {
      if(res) res = `${res}, Nome`;
      else res = `Nome`;
    }
    if(!processInfo?.last_name) {
      if(res) res = `${res}, Apelido`;
      else res = `Apelido`;
    }
    if(!processInfo?.address) {
      if(res) res = `${res}, Morada`;
      else res = `Morada`;
    }
    if(!processInfo?.postal_code) {
      if(res) res = `${res}, Código Postal`;
      else res = `Código Postal`;
    }
    if(!processInfo?.city) {
      if(res) res = `${res}, Cidade`;
      else res = `Cidade`;
    }
    if(!processInfo?.birthday) {
      if(res) res = `${res}, Data de Nascimento`;
      else res = `Data de Nascimento`;
    }
    if(!processInfo?.avs_number) {
      if(res) res = `${res}, Nº AVS`;
      else res = `Nº AVS`;
    }
  } 
  else if(type === 2) {
    if(!processInfo?.first_name) {
      if(res) res = `${res}, Nome`;
      else res = `Nome`;
    }
    if(!processInfo?.last_name) {
      if(res) res = `${res}, Apelido`;
      else res = `Apelido`;
    }
    if(!processInfo?.birthday) {
      if(res) res = `${res}, Data de Nascimento`;
      else res = `Data de Nascimento`;
    }
    if(!processInfo?.avs_number) {
      if(res) res = `${res}, Nº AVS`;
      else res = `Nº AVS`;
    }
    if(!processInfo?.address) {
      if(res) res = `${res}, Morada`;
      else res = `Morada`;
    }
    if(!processInfo?.postal_code) {
      if(res) res = `${res}, Código Postal`;
      else res = `Código Postal`;
    }
    if(!processInfo?.city) {
      if(res) res = `${res}, Cidade`;
      else res = `Cidade`;
    }
    if(!processInfo?.country) {
      if(res) res = `${res}, País`;
      else res = `País`;
    }
    if(!processInfo?.contact) {
      if(res) res = `${res}, Contacto`;
      else res = `Contacto`;
    }
  } 
  else if(type === 3) {
    if(!processInfo?.first_name) {
      if(res) res = `${res}, Nome`;
      else res = `Nome`;
    }
    if(!processInfo?.last_name) {
      if(res) res = `${res}, Apelido`;
      else res = `Apelido`;
    }
    if(!processInfo?.birthday) {
      if(res) res = `${res}, Data de Nascimento`;
      else res = `Data de Nascimento`;
    }
    if(!processInfo?.avs_number) {
      if(res) res = `${res}, Nº AVS`;
      else res = `Nº AVS`;
    }
    if(!processInfo?.responsible?.contact) {
      if(res) res = `${res}, Contacto Responsável`;
      else res = `Contacto Responsável`;
    }
    if(!processInfo?.gender) {
      if(res) res = `${res}, Género`;
      else res = `Género`;
    }
    if(!processInfo?.civil_state) {
      if(res) res = `${res}, Estado Civil`;
      else res = `Estado Civil`;
    }
    if(
      (
        processInfo?.civil_state === 2 ||
        processInfo?.civil_state === 3 ||
        processInfo?.civil_state === 4
      ) &&
      !processInfo?.civil_state_date
    ) {
      if(res) res = `${res}, Data Estado Civil`;
      else res = `Data Estado Civil`;
    }
  } 
  else if(type === 4) {
    if(!processInfo?.first_name) {
      if(res) res = `${res}, Nome`;
      else res = `Nome`;
    }
    if(!processInfo?.last_name) {
      if(res) res = `${res}, Apelido`;
      else res = `Apelido`;
    }
    if(!processInfo?.birthday) {
      if(res) res = `${res}, Data de Nascimento`;
      else res = `Data de Nascimento`;
    }
    if(!processInfo?.avs_number) {
      if(res) res = `${res}, Nº AVS`;
      else res = `Nº AVS`;
    }
  } 
  else if(type === 5) {
    if(!processInfo?.service) {
      if(res) res = `${res}, Serviço`;
      else res = `Serviço`;
    }
    if(!processInfo?.ref) {
      if(res) res = `${res}, Nº Processo`;
      else res = `Nº Processo`;
    }
    if(!processInfo?.responsible) {
      if(res) res = `${res}, Pessoa Responsável`;
      else res = `Pessoa Responsável`;
    }
    if(!processInfo?.first_name) {
      if(res) res = `${res}, Nome`;
      else res = `Nome`;
    }
    if(!processInfo?.last_name) {
      if(res) res = `${res}, Apelido`;
      else res = `Apelido`;
    }
    if(!processInfo?.address) {
      if(res) res = `${res}, Morada`;
      else res = `Morada`;
    }
    if(!processInfo?.postal_code) {
      if(res) res = `${res}, Código Postal`;
      else res = `Código Postal`;
    }
    if(!processInfo?.city) {
      if(res) res = `${res}, Cidade`;
      else res = `Cidade`;
    }
    if(!processInfo?.country) {
      if(res) res = `${res}, País`;
      else res = `País`;
    }
  } 
  else if(type === 6) {
    if(!processInfo?.first_name) {
      if(res) res = `${res}, Nome`;
      else res = `Nome`;
    }
    if(!processInfo?.last_name) {
      if(res) res = `${res}, Apelido`;
      else res = `Apelido`;
    }
    if(!processInfo?.birthday) {
      if(res) res = `${res}, Data de Nascimento`;
      else res = `Data de Nascimento`;
    }
    if(!processInfo?.avs_number) {
      if(res) res = `${res}, Nº AVS`;
      else res = `Nº AVS`;
    }
  } 
  else if(type === 7) {
    if(!processInfo?.first_name) {
      if(res) res = `${res}, Nome`;
      else res = `Nome`;
    }
    if(!processInfo?.last_name) {
      if(res) res = `${res}, Apelido`;
      else res = `Apelido`;
    }
    if(!processInfo?.avs_number) {
      if(res) res = `${res}, Nº AVS`;
      else res = `Nº AVS`;
    }
    if(!processInfo?.birthday) {
      if(res) res = `${res}, Data de Nascimento`;
      else res = `Data de Nascimento`;
    }
    if(!processInfo?.address) {
      if(res) res = `${res}, Morada`;
      else res = `Morada`;
    }
    if(!processInfo?.postal_code) {
      if(res) res = `${res}, Código Postal`;
      else res = `Código Postal`;
    }
    if(!processInfo?.city) {
      if(res) res = `${res}, Cidade`;
      else res = `Cidade`;
    }
    if(!processInfo?.country) {
      if(res) res = `${res}, País`;
      else res = `País`;
    }
    if(!processInfo?.gender) {
      if(res) res = `${res}, Género`;
      else res = `Género`;
    }
    if(!processInfo?.civil_state) {
      if(res) res = `${res}, Estado Civil`;
      else res = `Estado Civil`;
    }
    if(!processInfo?.email) {
      if(res) res = `${res}, Email`;
      else res = `Email`;
    }
    if(!processInfo?.contact) {
      if(res) res = `${res}, Contacto`;
      else res = `Contacto`;
    }
  } 
  else if(type === 10) {
    if(!processInfo?.service) {
      if(res) res = `${res}, Serviço`;
      else res = `Serviço`;
    }
    if(!processInfo?.ref) {
      if(res) res = `${res}, Nº Processo`;
      else res = `Nº Processo`;
    }
    if(!processInfo?.responsible) {
      if(res) res = `${res}, Pessoa Responsável`;
      else res = `Pessoa Responsável`;
    }
    if(!processInfo?.first_name) {
      if(res) res = `${res}, Nome`;
      else res = `Nome`;
    }
    if(!processInfo?.last_name) {
      if(res) res = `${res}, Apelido`;
      else res = `Apelido`;
    }
    if(!processInfo?.address) {
      if(res) res = `${res}, Morada`;
      else res = `Morada`;
    }
    if(!processInfo?.postal_code) {
      if(res) res = `${res}, Cód. Postal`;
      else res = `Cód. Postal`;
    }
    if(!processInfo?.city) {
      if(res) res = `${res}, Cidade`;
      else res = `Cidade`;
    }
    if(!processInfo?.country) {
      if(res) res = `${res}, País`;
      else res = `País`;
    }
    if(!processInfo?.price) {
      if(res) res = `${res}, Valor Total`;
      else res = `Valor Total`;
    }
  } 
  else if(type === 11) {
    if(!processInfo?.first_name) {
      if(res) res = `${res}, Nome`;
      else res = `Nome`;
    }
    if(!processInfo?.last_name) {
      if(res) res = `${res}, Apelido`;
      else res = `Apelido`;
    }
    if(!processInfo?.address) {
      if(res) res = `${res}, Morada`;
      else res = `Morada`;
    }
    if(!processInfo?.postal_code) {
      if(res) res = `${res}, Cód. Postal`;
      else res = `Cód. Postal`;
    }
    if(!processInfo?.city) {
      if(res) res = `${res}, Cidade`;
      else res = `Cidade`;
    }
    if(!processInfo?.country) {
      if(res) res = `${res}, País`;
      else res = `País`;
    }
    if(!processInfo?.birthday) {
      if(res) res = `${res}, Data de Nascimento`;
      else res = `Data de Nascimento`;
    }
    if(!processInfo?.avs_number) {
      if(res) res = `${res}, Nº AVS`;
      else res = `Nº AVS`;
    }
  } 
  else if(type === 12) {
    if(!processInfo?.first_name) {
      if(res) res = `${res}, Nome`;
      else res = `Nome`;
    }
    if(!processInfo?.last_name) {
      if(res) res = `${res}, Apelido`;
      else res = `Apelido`;
    }
    if(!processInfo?.address) {
      if(res) res = `${res}, Morada`;
      else res = `Morada`;
    }
    if(!processInfo?.postal_code) {
      if(res) res = `${res}, Cód. Postal`;
      else res = `Cód. Postal`;
    }
    if(!processInfo?.city) {
      if(res) res = `${res}, Cidade`;
      else res = `Cidade`;
    }
    if(!processInfo?.country) {
      if(res) res = `${res}, País`;
      else res = `País`;
    }
  }

  let error = false, abrevText = '', fullText = '';

  if(res) {
    error = true;
    fullText = `Campos em falta: ${res}`;

    let firstField = '', otherFields = 0;

    if(res.includes(',')) {
      const parts = res.split(',');

      firstField = parts[0];
      otherFields = parts.length - 1;        
    }
    else {
      firstField = res;
    }

    abrevText = `Campos em falta: ${firstField}`;

    if(otherFields > 0) {
      abrevText = `${abrevText}, +${otherFields}`;
    }
  }
  else {
    abrevText = fullText = 'Os campos necessários estão preenchidos.';
  }

  return {
    error,
    abrevText,
    fullText
  };
};
