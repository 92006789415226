import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { notification } from 'antd';
import { GetProfile, UpdateProfile } from '../../infra/requests/UsersRequests';
import { GetProfileCommissionsBalance } from '../../infra/requests/CommissionsRequests';
import { FormContainer, BaseForm, SpinLoading, FieldTitle, Separator, InputNote } from '../../styles/BasicStyles';
import { CommissionsStates } from '../../infra/utils/CommissionsStates';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from '../../redux/Menu/menu.actions';
import { getUserType } from '../../infra/utils/UserTypes';
import { SaveUser } from '../../redux/User/user.actions';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { BalanceTitle } from './components/styles';
import { CardWrapper, Card, CardContainer } from '../process/styles';
import { renderBalanceItem } from './components/Balance';
import { Row, Col } from 'antd';
import { commissionOptions } from '../../infra/utils/CommissionsStates';
import React, { Component } from 'react';
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import TextInput from '../../components/generic/inputs/TextInput';
import ImageInput from '../../components/generic/inputs/ImageInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import RadioButtons from '../../components/generic/inputs/RadioButtons';
import CurrencyInput from '../../components/generic/inputs/CurrencyInput';
import NumberInput from '../../components/generic/inputs/NumberInput';

const validations = FormValidator.make({
  name: 'required',
  email: 'required|email'
});

class ManageProfilePage extends Component {
  state = {
    loading: true,
    commissionServiceType: 0,
    commissionPaymentsType: 0,
    commissionLemaniaType: 0,
    type: 0,
    commissionsBalance: []
  };

  componentDidMount = async () => {
    await this.loadDetail();
  };

  loadDetail = async () => {
    const { dispatch, CloseMenu } = this.props;

    CloseMenu();

    const result = await GetProfile();
    dispatch(initialize('manage_profile_form', { ...result.data }));

    let balance = [];
    if(result?.data?.type === 1 || result?.data?.type === 3) {
      balance = await GetProfileCommissionsBalance();
    }

    this.setState({ 
      loading: false,
      commissionServiceType: result?.data?.commission_service_type || 0,
      commissionPaymentsType: result?.data?.commission_payments_type || 0,
      commissionLemaniaType: result?.data?.commission_lemania_type || 0,
      type: result?.data?.type || 0,
      commissionsBalance: balance?.data || []
    });
  }

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { user, dispatch } = this.props;

      const payload = FlattenToFormData(values);
      const request = await UpdateProfile(user._id, payload);

      this.setState({ loading: false });

      if(request.success) {
        dispatch(SaveUser(request.data));

        notification.success({
          message: "Sucesso!",
          description: "Perfil atualizado com sucesso."
        });

        return await this.loadDetail();
      }
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  render() {
    const { handleSubmit, pristine, user } = this.props;
    const { loading, type, commissionsBalance, commissionServiceType, commissionPaymentsType, commissionLemaniaType } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          title='O Meu Perfil'
          breadcrumbs={['Editar']}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            }
          ]}
        />
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <FieldTitle>Tipo: <span>{ getUserType(user?.type) }</span></FieldTitle>
            <Field
              component={TextInput}
              name='name'
              type='text'
              label='Nome *'
              placeholder='Primeiro e último nome'
            />
            <Field
              component={TextInput}
              name='email'
              type='email'
              label='Email *'
              placeholder='Email do utilizador'
            />
            <Field
              component={TextInput}
              name='contact'
              type='text'
              label='Contacto'
              placeholder='Contacto do utilizador'
            />
            {
              user.super_admin && (type === 1 || type === 3) &&
              <>
                <Separator/>
                <Field
                  component={RadioButtons}
                  name={'commission_service_type'}
                  options={commissionOptions}
                  label={'Comissão dos Serviços *'}
                  execAfterChange={(value) => this.setState({ commissionServiceType: value })}
                  first
                />
                {
                  commissionServiceType === 1 ?
                  <Row gutter={24}>
                    <Col md={12} xs={24}>
                      <Field
                        component={CurrencyInput}
                        name={'commission_service_chf'}
                        label={'Valor (CHF)'}
                        suffix={'CHF'}
                      />
                    </Col>
                    <Col md={12} xs={24}>
                      <Field
                        component={CurrencyInput}
                        name={'commission_service_eur'}
                        label={'Valor (Euro)'}
                        suffix={'€'}
                      />
                    </Col>
                  </Row>
                  :
                  commissionServiceType === 2 ?
                  <Field
                    component={NumberInput}
                    name={'commission_service_perc'}
                    label={'Valor (%)'}
                    placeholder={'0.00%'}
                    min={0}
                    max={100}
                  />
                  :
                  null
                }
                <Separator/>
                <Field
                  component={RadioButtons}
                  name={'commission_payments_type'}
                  options={commissionOptions}
                  label={'Comissão de Pagamentos *'}
                  execAfterChange={(value) => this.setState({ commissionPaymentsType: value })}
                  first
                />
                {
                  commissionPaymentsType === 1 ?
                  <Row gutter={24}>
                    <Col md={12} xs={24}>
                      <Field
                        component={CurrencyInput}
                        name={'commission_payments_chf'}
                        label={'Valor (CHF)'}
                        suffix={'CHF'}
                      />
                    </Col>
                    <Col md={12} xs={24}>
                      <Field
                        component={CurrencyInput}
                        name={'commission_payments_eur'}
                        label={'Valor (Euro)'}
                        suffix={'€'}
                      />
                    </Col>
                  </Row>
                  :
                  commissionPaymentsType === 2 ?
                  <Field
                    component={NumberInput}
                    name={'commission_payments_perc'}
                    label={'Valor (%)'}
                    placeholder={'0.00%'}
                    min={0}
                    max={100}
                  />
                  :
                  null
                }
                <Separator/>
                <Field
                  component={RadioButtons}
                  name={'commission_lemania_type'}
                  options={commissionOptions}
                  label={'Comissão da Lemania *'}
                  execAfterChange={(value) => this.setState({ commissionLemaniaType: value })}
                  first
                />
                {
                  commissionLemaniaType === 1 ?
                  <Field
                    component={CurrencyInput}
                    name={'commission_lemania'}
                    label={'Valor (CHF)'}
                    suffix={'CHF'}
                  />
                  :
                  commissionLemaniaType === 2 ?
                  <Field
                    component={NumberInput}
                    name={'commission_lemania'}
                    label={'Valor (%)'}
                    placeholder={'0.00%'}
                    min={0}
                    max={100}
                  />
                  :
                  null
                }
              </>
            }
            {
              type === 2 &&
              <React.Fragment>
                <Separator/>
                <Field
                  component={ImageInput}
                  name='image'
                  label='Logo do Agente'
                  noCrop={true}
                />
                <InputNote style={{ fontSize: '15px', marginBottom: '20px' }}><span>NOTA:</span> Selecione uma imagem com uma largura máxima de 150px! Caso contrário poderá desformatar os emails.</InputNote>
                <Field
                  component={TextAreaInput}
                  name={`address`}
                  type='text'
                  label={'Morada'}
                  placeholder={'Morada'}
                />
                <Field
                  component={TextInput}
                  name={`cod_postal`}
                  type='text'
                  label={'Cód. Postal'}
                  placeholder={'Cód. Postal'}
                />
                <Field
                  component={TextInput}
                  name={`city`}
                  type='text'
                  label={'Cidade'}
                  placeholder={'Cidade'}
                />
                <Field
                  component={TextInput}
                  name={`country`}
                  type='text'
                  label={'País'}
                  placeholder={'País'}
                />
              </React.Fragment>
            }
            {
              (type === 1 || type === 3) && commissionsBalance?.length > 0 &&
              <CardWrapper gutter={24} style={{ marginTop: 30 }}>
                <Card xs={24}>
                  <CardContainer>
                    <BalanceTitle>Balanço Comissões</BalanceTitle>
                    {renderBalanceItem('Total Comissões', 0, commissionsBalance[0])}
                    {
                      CommissionsStates
                      .filter(state => state.showProfile)
                      .map((state, index) =>
                        renderBalanceItem(state.name, index + 1, commissionsBalance.find(elem => elem.state === state._id), state.fontColor)
                      )
                    }
                  </CardContainer>
                </Card>
              </CardWrapper>
            }
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageProfilePage = reduxForm({
  form: 'manage_profile_form',
  validate: validations,
})(ManageProfilePage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu, SaveUser }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageProfilePage));