/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd';
import { Separator } from '../../../../styles/BasicStyles';
import { ActionsWrapper, ActionsCol, DateResent } from '../styles';
import { CardWrapper, Card, CardContainer, CardItem, CardTitle, CardValue, MissingLabel } from '../../styles';
import { ReactSVG } from 'react-svg';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';
import { getCivilState } from '../../../../infra/utils/CivilStates';
import { getProfessionalState } from '../../../../infra/utils/ProfessionalStates';
import { getGender } from '../../../../infra/utils/Genders';
import { GetClientsList } from '../../../../infra/requests/ClientsRequests';
import { getCountry } from '../../../../infra/utils/Countries';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import BaseButton from '../../../../components/generic/buttons/BaseButton';
import Form from './Form';

const PersonalData = ({ processInfo, getInfo, clientInfo, dispatch, user }) => {
  const [edit, setEdit] = useState(false);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const init = async () => {
      try {
        const clientsList = await GetClientsList();
        setClients(clientsList?.data);
      }
      catch(error) {
        console.log(error);
      }
    }

    if(user?.type === 1) init();
  }, []);

  const openEditForm = () => {
    dispatch(initialize('edit_process_form', { ...processInfo }));
    setEdit(true);
  }

  return (
    <>
      <CardWrapper gutter={24}>
        <Card xs={24}>
          {
            edit ?
            <Form 
              setEdit={setEdit}
              getInfo={getInfo}
              processInfo={processInfo}
              clients={clients}
            />
            :
            <>
              <CardContainer>
                <ActionsWrapper>
                  <ActionsCol>
                    {
                      !processInfo?.agent && !processInfo?.partner &&
                      <CardItem>
                        <CardTitle style={{ fontSize: '18px', lineHeight: '28px' }}>Cliente:</CardTitle>
                        <CardValue style={{ fontSize: '18px', lineHeight: '28px' }}>
                          {!processInfo.agent && !processInfo.partner && clientInfo?.ref ? `${clientInfo.ref} - ${clientInfo?.first_name} ${clientInfo?.last_name}` : '-'}
                        </CardValue>
                      </CardItem>
                    }
                  </ActionsCol>
                  <ActionsCol>
                    {
                      !processInfo.archived &&
                      <BaseButton
                        htmlType='button'
                        type='secondary'
                        icon='edit'
                        text='Editar'
                        style={{ float: 'right' }}
                        onClick={() => openEditForm()}
                      />
                    }
                  </ActionsCol>
                </ActionsWrapper>
                {
                  !processInfo.agent && !processInfo.partner &&
                  <Row gutter={24} style={{ marginTop: '5px' }}>
                    <Col xs={24}>
                      <CardItem>
                        <CardTitle style={{ width: '190px' }}>Mensagem do cliente:</CardTitle>
                        <CardValue style={{ maxWidth: '600px' }}>{processInfo.message || '-'}</CardValue>
                      </CardItem>
                    </Col>
                  </Row>
                }
                <Row gutter={24} style={{ marginTop: '30px' }}>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Nome:</CardTitle>
                      <CardValue>{processInfo.full_name || '-'}</CardValue>
                    </CardItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Email:</CardTitle>
                      <CardValue>{processInfo.email || '-'}</CardValue>
                    </CardItem>
                  </Col>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Contacto:</CardTitle>
                      <CardValue>{processInfo.contact || '-'}</CardValue>
                    </CardItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Nº AVS/AHV:</CardTitle>
                      <CardValue>{processInfo.avs_number || '-'}</CardValue>
                    </CardItem>
                  </Col>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Data de Nascimento:</CardTitle>
                      <CardValue>{processInfo.birthday ? moment(processInfo.birthday).format('DD-MM-YYYY') : '-'}</CardValue>
                    </CardItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Data prevista de Regresso a Portugal:</CardTitle>
                      <CardValue noExpandWidth='true'>
                        {
                          processInfo.dateComeback ?
                          <>
                            {moment(processInfo.dateComeback).format('DD-MM-YYYY')}
                            <DateResent>
                              Email enviado a:&nbsp;
                              {processInfo?.dateComebackSent ? (
                                <span>
                                  {moment(processInfo?.dateComebackSent).format("DD-MM-YYYY, HH:mm")}h
                                </span>
                              ) : (
                                <span>N/A</span>
                              )}
                            </DateResent>
                          </>
                          :
                          <MissingLabel>
                            <ReactSVG src={`${process.env.REACT_APP_BO_URL}warning.svg`} />
                            <span>Sem Data de Regresso</span>
                          </MissingLabel>
                        }
                      </CardValue>
                    </CardItem>
                  </Col>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Data prevista da Reforma:</CardTitle>
                      <CardValue noExpandWidth='true'>
                        {
                          processInfo.dateRetirement ?
                          <>
                            {moment(processInfo.dateRetirement).format('DD-MM-YYYY')}
                            <DateResent>
                              Email enviado a:&nbsp;
                              {processInfo?.dateRetirementSent ? (
                                <span>
                                  {moment(processInfo?.dateRetirementSent).format("DD-MM-YYYY, HH:mm")}h
                                </span>
                              ) : (
                                <span>N/A</span>
                              )}
                            </DateResent>
                          </>
                          :
                          <MissingLabel>
                            <ReactSVG src={`${process.env.REACT_APP_BO_URL}warning.svg`} />
                            <span>Sem Data de Reforma</span>
                          </MissingLabel>
                        }
                      </CardValue>
                    </CardItem>
                  </Col>
                </Row>
              </CardContainer>
              <Separator />
              <CardContainer>
                <Row gutter={24}>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Situação Profissional:</CardTitle>
                      <CardValue>{processInfo.professional_state ? getProfessionalState(processInfo.professional_state)?.name : '-'}</CardValue>
                    </CardItem>
                  </Col>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Género:</CardTitle>
                      <CardValue>{processInfo.gender ? getGender(processInfo.gender)?.name : '-'}</CardValue>
                    </CardItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Estado civil:</CardTitle>
                      <CardValue>{processInfo.civil_state ? getCivilState(processInfo.civil_state)?.name : '-'}</CardValue>
                    </CardItem>
                  </Col>
                  {
                    (processInfo.civil_state === 2 || processInfo.civil_state === 3 || processInfo.civil_state === 4) && processInfo.civil_state_date &&
                    <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                      <CardItem>
                        <CardTitle style={{ width: '190px' }}>{`${getCivilState(processInfo.civil_state)?.name} desde`}:</CardTitle>
                        <CardValue>{moment(processInfo.civil_state_date).format('DD-MM-YYYY')}</CardValue>
                      </CardItem>
                    </Col>
                  }
                </Row>
                <Row gutter={24}>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Morada:</CardTitle>
                      <CardValue>{processInfo.address || '-'}</CardValue>
                    </CardItem>
                  </Col>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Código Postal:</CardTitle>
                      <CardValue>{processInfo.postal_code || '-'}</CardValue>
                    </CardItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>Cidade:</CardTitle>
                      <CardValue>{processInfo.city || '-'}</CardValue>
                    </CardItem>
                  </Col>
                  <Col lg={12} xs={24} style={{ paddingBottom: '15px' }}>
                    <CardItem>
                      <CardTitle style={{ width: '190px' }}>País:</CardTitle>
                      <CardValue>{processInfo.country ? getCountry(processInfo.country) : '-'}</CardValue>
                    </CardItem>
                    {
                      // Show old country stored as string. New BO we have a select
                      processInfo.old_country &&
                      <CardItem>
                        <CardTitle small style={{ width: '190px' }}>País (Antigo):</CardTitle>
                        <CardValue small>{processInfo.old_country || '-'}</CardValue>
                      </CardItem>
                    }
                  </Col>
                </Row>
              </CardContainer>
            </>
          }
        </Card>
      </CardWrapper>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(PersonalData);
