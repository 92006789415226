/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change as ChangeField, initialize } from 'redux-form';
import { ModalTitle, ModalButtonWrapper, MissingLabel } from '../../styles';
import { FlattenToFormData } from '../../../../infra/services/formdata/TransformToFormData';
import { SaveProcessUpdate } from '../../../../infra/requests/ProcessRequests';
import { ReactSVG } from 'react-svg';
import React, { useEffect, useState } from 'react';
import DateInput from '../../../../components/generic/inputs/DateInput';
import TextAreaInput from '../../../../components/generic/inputs/TextAreaInput';
import FormValidator from '../../../../infra/services/validations/FormValidator';
import FileUpload from '../../components/FileUpload';
import BaseButton from '../../../../components/generic/buttons/BaseButton';

const validations = FormValidator.make({
  date: 'required',
  message: 'required'
});

const NewUpdate = ({ open, handleSubmit, closeModal, addressList, file_template, dispatch, processInfo, getInfo, documents, reports }) => {
  const [loading, setLoading] = useState(false);
  const [addressSelect, setAddressSelect] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [subjectSelect, setSubjectSelect] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  useEffect(() => {
    dispatch(initialize('new_update_form'));
    
    setAddressSelect(false);
    setAddressError(false);
    setSubjectSelect(false);
    setSubjectError(false);
    setAcceptedFiles([]);
    setRejectedFiles([]);
  }, [open]);
  
  const onSubmit = async (values) => {
    try {
      setLoading(true);

      /* 
      * In case the user selected a file that needed an addres or subject (Procuração for example), don't let submit unless they select something in that field
      * Otherwise, the user thinks he added a file, but he didn't
      */
      if(addressSelect) {
        setAddressError(true);
        setLoading(false);

        return notification.error({
          message: 'Deverá selecionar uma morada para completar o ficheiro selecionado! Caso contrário o ficheiro não será anexado.'
        });
      }
      if(subjectSelect) {
        setSubjectError(true);
        setLoading(false);

        return notification.error({
          message: 'Deverá selecionar um assunto para completar o ficheiro selecionado! Caso contrário o ficheiro não será anexado.'
        });
      }

      // Origin: 1 » from Administrator in BO / 2 » from client in Front
      const payload = FlattenToFormData({ ...values, process: processInfo._id, origin: 1, selectedFiles: acceptedFiles });
      const { success  } = await SaveProcessUpdate(payload);

      if(success) {
        notification.success({
          message: 'Atualização inserida com sucesso!'
        });

        await getInfo();
        dispatch(initialize('new_update_form'));
        setAddressSelect(false);
        setAddressError(false);
        setSubjectSelect(false);
        setSubjectError(false);
        setAcceptedFiles([]);
        setRejectedFiles([]);
        closeModal();
      }

      setLoading(false);
    }
    catch(error) {
      console.log(error);
      setLoading(false);
    }
  }

  const checkSelectedTemplate = (value) => {
    //In these types we must select an address to complete the file
    if(parseInt(value) === 1 || parseInt(value) === 3 || parseInt(value) === 4) {
      setAddressSelect(true);
      setSubjectSelect(false);
    }
    //In this type we must select a subject to complete the file
    else if(parseInt(value) === 10) {
      setAddressSelect(false);
      setSubjectSelect(true);
    }
    //Otherwise we can add the file to the selected array
    else {
      setAddressSelect(false);
      setSubjectSelect(false);
      setAcceptedFiles([ ...acceptedFiles, { file_template: value } ]);

      //Clear Template field
      dispatch(ChangeField('new_update_form', 'file_template', null));
    }
  }

  const checkSelectedAddress = (value) => {
    setAcceptedFiles([ ...acceptedFiles, { file_template, address: value } ]);
    setAddressSelect(false);
    setAddressError(false);

    //Clear Template field
    dispatch(ChangeField('new_update_form', 'file_template', null));

    //Clear Address field
    dispatch(ChangeField('new_update_form', 'address', null));
  }

  const checkSelectedSubject = (value) => {
    setAcceptedFiles([ ...acceptedFiles, { file_template, subject: value } ]);
    setSubjectSelect(false);
    setSubjectError(false);

    //Clear Template field
    dispatch(ChangeField('new_update_form', 'file_template', null));

    //Clear Subject field
    dispatch(ChangeField('new_update_form', 'subject', null));
  }

  const checkSelectedDocument = (value) => {
    const file = documents.find(elem => elem._id === value);

    setAcceptedFiles([ ...acceptedFiles, { document: value, filename: file?.name || '', size: file?.size || 0 } ]);

    //Clear Document field
    dispatch(ChangeField('new_update_form', 'document', null));
  }

  const checkSelectedReport = (value) => {
    const file = reports.find(elem => elem._id === value);

    setAcceptedFiles([ ...acceptedFiles, { report: value, filename: file?.name || '', size: file?.size || 0 } ]);

    //Clear Report field
    dispatch(ChangeField('new_update_form', 'report', null));
  }

  return (
    <Modal
      visible={open}
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
    >
      <>
        <ModalTitle>Nova Atualização</ModalTitle>
        {
          !processInfo?.email &&
          <MissingLabel>
            <ReactSVG src={`${process.env.REACT_APP_BO_URL}warning.svg`} />
            <span>Deverá inserir o email do cliente no Processo antes de inserir uma Atualização.</span>
          </MissingLabel>
        }
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            component={DateInput}
            name='date'
            label={'Data *'}
            placeholder={'Selecione uma data'}
          />
          <Field
            component={TextAreaInput}
            name='message'
            label={'Mensagem *'}
            placeholder={'Introduza uma mensagem'}
          />
          <FileUpload 
            addressList={addressList}
            checkSelectedTemplate={checkSelectedTemplate}
            checkSelectedAddress={checkSelectedAddress}
            checkSelectedSubject={checkSelectedSubject}
            checkSelectedDocument={checkSelectedDocument}
            checkSelectedReport={checkSelectedReport}
            addressSelect={addressSelect}
            subjectSelect={subjectSelect}
            acceptedFiles={acceptedFiles}
            setAcceptedFiles={setAcceptedFiles}
            rejectedFiles={rejectedFiles}
            setRejectedFiles={setRejectedFiles}
            documents={documents}
            reports={reports}
            processInfo={processInfo}
            addressError={addressError}
            subjectError={subjectError}
          />
          <ModalButtonWrapper>
            <BaseButton
              type='primary'
              loading={loading}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              text="Guardar"
            />
          </ModalButtonWrapper>
        </Form>
      </>
    </Modal>
  );
};

const selector = formValueSelector('new_update_form');

const NewUpdateForm = reduxForm({
  form: 'new_update_form',
  validate: validations
})(NewUpdate);

const mapStateToProps = state => ({
  file_template: selector(state, 'file_template')
});

export default connect(mapStateToProps)(NewUpdateForm);
